import {httpDelete, httpGet, httpPost} from "../libs/http";

export const loadLogs = (page, per_page, filter = null, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/logs/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadCompanyLogs = (guid, page, per_page, filter = null, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/companies/' + guid + '/logs/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadLogins = (page, per_page, filter = null, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/logins/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

// Load all companies for the current user

export const loadCompanies = (page, per_page, filter = null, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/companies/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadCompany = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/companies/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createCompany = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/companies/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateCompany = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/companies/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateCompanyInvoiceNotes = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/companies/' + guid + '/invoice-notes/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const makeCompanyDefault = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/companies/' + guid + '/make_default/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadProfile = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/profile/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateProfile = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/profile/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const validateCompany = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/validate_company/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadCompanyUsers = (guid, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/companies/' + guid + '/users/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadCompanyUser = (guid, userGuid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/companies/' + guid + '/users/' + userGuid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createCompanyUser = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/companies/' + guid + '/users/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateCompanyUser = (guid, userGuid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/companies/' + guid + '/users/' + userGuid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};
export const deleteCompanyUser = (guid, userGuid, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/account/companies/' + guid + '/users/' + userGuid + '/',(res) => onSuccess(res), (err) => onFailure(err));
};

export const loadUserInvitations = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/invitations/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadInvitationCode = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/invitations/code/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const inviteUser = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/companies/' + guid + '/invitations/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const revokeInvitation = (guid, invitation_guid, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/account/companies/' + guid + '/invitations/' + invitation_guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const revokeUserInvitation = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/account/invitations/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const acceptUserInvitation = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/invitations/' + guid + '/accept/', {}, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updatePassword = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/password/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadVat = (guid, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/companies/' + guid + '/vat/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadEmails = (page, per_page, filters, onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/account/emails/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadEmail = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/emails/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadCompanyEmails = (guid, page, per_page, filters, onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/account/companies/' + guid + '/emails/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadCompanyEmail = (guid, email_guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/companies/' + guid + '/emails/' + email_guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const addVat = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/companies/' + guid + '/vat/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const disableVat = (guid, vat_guid, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/account/companies/' + guid + '/vat/' + vat_guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadCompanyContacts = (guid, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/companies/' + guid + '/contacts/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const createContact = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/companies/' + guid + '/contacts/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const editContact = (guid, contact_guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/companies/' + guid + '/contacts/' + contact_guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadContact = (guid, contact_guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/companies/' + guid + '/contacts/' + contact_guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const resendVerification = (guid, contact_guid, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/companies/' + guid + '/contacts/' + contact_guid + '/verification/', { resend_email: true }, (res) => onSuccess(res), (err) => onFailure(err));
};

export const verifyContactEmail = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/account/companies/contacts/verification/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteContact = (guid, contact_guid, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/account/companies/' + guid + '/contacts/' + contact_guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadNotifications = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/notifications/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const markNotificationsRead = (onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/notifications/', null, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadPartnerDetails = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/partner-details/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const updatePartnerDetails = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/partner-details/',  data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const setUp2FA = (onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/2fa/',  {}, (res) => onSuccess(res), (err) => onFailure(err));
};

export const verify2FACode = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/2fa/' + guid + '/',  data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const get2FA = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/2fa/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const disable2FA = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/2fa/disable/',  data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const get2FABackupCodes = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/account/2fa/backup-codes/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const generate2FABackupCodes = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/2fa/backup-codes/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updatePreferences = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/account/profile/preferences/', data, (res) => onSuccess(res), (err) => onFailure(err));
};